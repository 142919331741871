.customField {
  margin-bottom: 24px;

  .formMargins {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }

  @media (--viewportMedium) {
    composes: formMargins;
  }
}