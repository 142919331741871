@import '../../styles/customMediaQueries.css';

/* General card container */
.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%; /* Adjust to fit parent container */
  max-width: 300px; /* Ensure cards don't become too wide */
  border-radius: var(--borderRadiusMedium);
  overflow: hidden;
  background: var(--colorWhite);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  transition: var(--transitionStyleButton), box-shadow 0.3s ease; /* Add specific transition for box-shadow */
  background-color: #fff;
  padding: 10px;
  border: 1px solid #d3d3d3;
  /* Hover effect */
  &:hover {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  }
}

.divider {
  height: 1px; /* Adjust the thickness of the divider */
  background: linear-gradient(to right, transparent, #d3d3d3, transparent);
  margin: 5px 0; /* Adjust the vertical spacing around the divider */
  border: none;
}

.cardWrapper {
  position: relative;
}

/* Image container with aspect ratio */
.aspectRatioWrapper {
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  padding-top: 2%; /* 4:3 Aspect Ratio */
  overflow: hidden;
  position: relative;
}
.ownListingOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.ownListingTitle {
  color: var(--colorWhite);
  font-weight: var(--fontWeightBold);
  font-size: 1rem;
  margin: 0;
  line-height: 0;
}

.rootForImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadiusMedium);
}

/* Information section */
.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  background: var(--colorWhite);
  border-radius: 16px;
  overflow: hidden;
  flex: 1;
}

/* Header info including title and price */
.headerInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
}

/* Title text */
.title {
  /* Font */
  composes: textLarge from global;
  font-weight: var(--fontWeightBold);
  color: #000;
  margin: 0;
  font-size: 1.5rem;

  /* Ensure title is truncated if too long */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%; /* Adjust this based on available space */
}

.price {
  margin-bottom: 4px; /* Space between header and other content */
}
.priceValue {
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--colorPrimary);
  font-size: 1rem;
  letter-spacing: -0.5px;
  color: rgba(0, 99, 178);
}

.perUnit {
  margin-left: 4px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorGrey600);
}

.authorWrapper {
  padding-left: 8px;
  padding-right: 8px;
}

.listingLink {
  text-decoration: none;
  color: inherit;
}

/* Author info section */
.authorInfo {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);
  z-index: 1;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.wishlistIcon {
  width: 1.2rem;
  height: 1.2rem;
  fill: currentColor; /* This ensures the SVG takes the color of its parent */
  cursor: pointer;
}
.wishlistIconFill {
  color: #fe251b; /* Red color for filled heart */
}
.wishlist {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--fontWeightMedium);
  font-size: 0.9rem;
  background-color: #fff;
  height: 2rem;
  width: 2rem;
  position: absolute;
  color: #fe251b;
  top: 1rem;
  right: 1.5rem;
  border-radius: 50%; /* Changed to 50% for a perfect circle */
  text-transform: capitalize;
  padding: 0; /* Removed padding as it's not needed with flexbox centering */
  z-index: 1;
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

/* Responsive adjustments */
@media (--viewportMedium) {
  .cardWrapper:hover {
    box-shadow: var(--boxShadowListingCard);
  }

  .title {
    margin-top: 0;
    margin-bottom: 0;
  }

  .priceValue {
    margin-top: 0;
    margin-bottom: 0;
  }

  .perUnit {
    margin-top: 0;
    margin-bottom: 0;
  }

  .authorInfo {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.condition {
  font-weight: var(--fontWeightBold);
  font-size: 0.9rem;
  color: white;
  text-align: center;
  padding: 0.2rem 0.5rem;
  border-radius: 1.2rem;
  text-transform: capitalize;
  margin-top: 0rem;
  margin-bottom: 0rem;
  line-height: 1.5rem;
}

.new {
  background-color: #22c55e;
  width: 3.5rem;
}

.used {
  background-color: #f7b500;
  width: 3.5rem;
}

.broken {
  background-color: #ff4d4f;
  width: 4.5rem;
}
